import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

const Seo = ({ title, description, url, image }) => {
  return (
    <Helmet
      htmlAttributes={{
        lang: 'id',
      }}
    >
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta property="og:site_name" content={`Gubelinlab`} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="675" />

      <meta name="twitter:site" content={`Gubelinlab`} />
      <meta name="twitter:creator" content={`Gubelinlab`} />
      <meta property="twitter:url" content={url} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
      <meta property="twitter:image" content={image} />

      <meta name="url" content={url} />
      <link rel="canonical" href={url} />
      <link
        type="application/opensearchdescription+xml"
        rel="search"
        href="https://gubelinlab.com/opensearch.xml"
      />
    </Helmet>
  )
}

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.string,
}

export default Seo
