import React from 'react'
import Header from './Header'
import Footer from './Footer'

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col bg-white font-sans antialiased text-gray-500 min-h-screen">
      <a href={`#main-content`} className={`sr-only`}>
        Skip To Content
      </a>
      <Header />
      <main id="main-content" className="flex-1">
        {children}
      </main>
      <Footer />
    </div>
  )
}

export default Layout
