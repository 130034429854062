import React from 'react'
import { useState } from 'react'
import { Transition } from '@headlessui/react'
import { globalHistory } from '@reach/router'
import { Link } from 'gatsby'
import Icon from './Icon'
import Logo from './Logo'
import SearchBar from './SearchBar'
function Header() {
  const [isOpen, setIsOpen] = useState(false)
  const path = globalHistory.location.pathname
  return (
    <header className="relative w-full h-14 shadow-lg">
      <div className="fixed z-50 bg-gray-900 w-full">
        <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
          <div className="relative flex items-center justify-between h-16">
            <div className="flex items-center px-2 lg:px-0">
              <div className="flex-shrink-0">
                <Link to="/">
                  <span className="sr-only">Gubelinlab</span>
                  <Icon htmlClass={`block lg:hidden h-8 w-auto`} />
                  <Logo htmlClass={`hidden lg:block h-8 w-auto`} textColor={`#fff`} />
                </Link>
              </div>
              <div className="hidden lg:block lg:ml-6">
                <div className="flex space-x-4">
                  <Link
                    to="/"
                    className={`${
                      path === '/' ? 'bg-rose-900' : ''
                    } text-white px-3 py-2 rounded-md text-sm font-medium `}
                  >
                    Beranda
                  </Link>
                  <Link
                    to="/tentang-kami/"
                    className={`${
                      path === '/tentang-kami/' ? 'bg-rose-900' : ''
                    } text-white px-3 py-2 rounded-md text-sm font-medium `}
                  >
                    Tentang Kami
                  </Link>
                  <Link
                    to="/hubungi-kami/"
                    className={`${
                      path === '/hubungi-kami/' ? 'bg-rose-900' : ''
                    } text-white px-3 py-2 rounded-md text-sm font-medium `}
                  >
                    Hubungi Kami
                  </Link>
                  <Link
                    to="/kebijakan-privasi/"
                    className={`${
                      path === '/kebijakan-privasi/' ? 'bg-rose-900' : ''
                    } text-white px-3 py-2 rounded-md text-sm font-medium `}
                  >
                    Kebijakan Privasi
                  </Link>
                  <Link
                    to="/tips-judi-online/"
                    className={`${
                      path === '/tips-judi-online/' ? 'bg-rose-900' : ''
                    } text-white px-3 py-2 rounded-md text-sm font-medium `}
                  >
                    Tips Judi Online
                  </Link>
                </div>
              </div>
            </div>
            <div className="flex-1 flex justify-center px-2 lg:ml-6 lg:justify-end">
              <div className="max-w-lg w-full lg:max-w-xs">
                <SearchBar />
              </div>
            </div>

            <div className="flex lg:hidden">
              <button
                type="button"
                onClick={() => setIsOpen(!isOpen)}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>

                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <Transition
          show={isOpen}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {(ref) => (
            <div
              ref={ref}
              className={`z-50 absolute top-0 inset-x-0 max-w-3xl mx-auto w-full p-2 transition transform origin-top-right md:hidden ${
                !isOpen ? 'hidden' : ''
              }`}
            >
              <div className="rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y divide-gray-200">
                <div className="pt-3 pb-2">
                  <div className="flex items-center justify-between px-4">
                    <div className="flex items-center justify-center">
                      <svg className="h-8 w-auto mx-auto" viewBox="0 0 150 28">
                        <path
                          d="M14,0A14,14,0,1,0,28,14,14,14,0,0,0,14,0ZM6.2,21.8A10.85,10.85,0,0,1,3,14.2L13.89,25A11.33,11.33,0,0,1,6.2,21.8Zm10.19,2.9L3.3,11.6A11,11,0,0,1,22.89,7.5l-1.5,1.3A9.23,9.23,0,0,0,14,5,9.08,9.08,0,0,0,5.5,11L17,22.5a9.07,9.07,0,0,0,5.8-6.5H18V14h7A11,11,0,0,1,16.39,24.7Z"
                          fill="#e9415f"
                        />
                        <path
                          d="M46.62,13.81h7.3V14c0,1.07-.14,4.42-2.34,6.59a7.58,7.58,0,0,1-5.65,2.1,7.65,7.65,0,0,1-5.78-2.15,8.51,8.51,0,0,1-2.37-6.14,8.14,8.14,0,0,1,2.34-5.92A8.5,8.5,0,0,1,46.4,6a7.66,7.66,0,0,1,3.68.84,7.41,7.41,0,0,1,3,2.91l-2.69,1.46A4.39,4.39,0,0,0,48.5,9.36a5.24,5.24,0,0,0-2.24-.5,5.41,5.41,0,0,0-3.68,1.41A5.94,5.94,0,0,0,41,14.48a5.39,5.39,0,0,0,1.58,4,4.86,4.86,0,0,0,3.53,1.37,5.3,5.3,0,0,0,3.13-1,4.24,4.24,0,0,0,1.5-2.44h-4.1ZM59.27,12v5.85a2.35,2.35,0,0,0,.62,1.82,2.33,2.33,0,0,0,3,0,2.31,2.31,0,0,0,.62-1.82V12h2.92v6.33a3.91,3.91,0,0,1-1.27,3.18,6.59,6.59,0,0,1-7.5,0,3.93,3.93,0,0,1-1.26-3.18V12Zm12.8,1.34a4,4,0,0,1,3.37-1.67c3,0,5.08,2.36,5.08,5.49s-2.1,5.52-5.16,5.52A3.91,3.91,0,0,1,72.07,21v1.34H69.16V4.92h2.91Zm-.29,3.82a2.87,2.87,0,1,0,5.73,0,2.87,2.87,0,1,0-5.73,0Zm21.13,2.3a5.11,5.11,0,0,1-5.08,3.22,5.43,5.43,0,0,1-4-1.46,5.22,5.22,0,0,1-1.62-4,5.48,5.48,0,0,1,1.58-4.07,5.29,5.29,0,0,1,3.89-1.45,4.92,4.92,0,0,1,4,1.69A6.19,6.19,0,0,1,93,17.41v.24H85.25a3.1,3.1,0,0,0,.5,1.75,2.45,2.45,0,0,0,2.08,1A2.67,2.67,0,0,0,90.24,19Zm-2.67-3.66a2.48,2.48,0,0,0-4.9,0Zm7.9-10.87V22.34H95.23V4.92Zm4.23,1a1.74,1.74,0,1,1-1.75,1.74A1.75,1.75,0,0,1,102.37,5.88ZM103.82,12V22.34h-2.91V12Zm2.77,0h2.92v1.34a3.76,3.76,0,0,1,3.2-1.67,4,4,0,0,1,3.05,1.26,4.35,4.35,0,0,1,.81,2.92v6.5h-2.91V16.7a2.93,2.93,0,0,0-.55-2,1.85,1.85,0,0,0-1.36-.53,2,2,0,0,0-1.79.91,3.56,3.56,0,0,0-.45,2v5.28h-2.92Zm15.67-7.07V22.34h-2.92V4.92ZM133,12h2.91V22.34H133V21a3.71,3.71,0,0,1-3.17,1.67,5.25,5.25,0,0,1-5.28-5.52,5.17,5.17,0,0,1,5.13-5.49A3.74,3.74,0,0,1,133,13.33Zm-5.44,5.16a2.87,2.87,0,1,0,5.73,0,2.87,2.87,0,1,0-5.73,0Zm14-3.82a4,4,0,0,1,3.36-1.67C148,11.66,150,14,150,17.15s-2.1,5.52-5.16,5.52A3.9,3.9,0,0,1,141.55,21v1.34h-2.92V4.92h2.92Zm-.29,3.82a2.87,2.87,0,1,0,5.73,0,2.87,2.87,0,1,0-5.73,0Z"
                          fill="#000000"
                        />
                      </svg>
                    </div>
                    <div className="-mr-2">
                      <button
                        type="button"
                        onClick={() => setIsOpen(!isOpen)}
                        className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                      >
                        <span className="sr-only">Close menu</span>
                        <svg
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="mt-3 px-2 space-y-1">
                    <Link
                      to="/"
                      className={`${
                        path === '/' ? 'bg-gray-100' : ''
                      } block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800`}
                    >
                      Beranda
                    </Link>
                    <Link
                      to="/tentang-kami/"
                      className={`${
                        path === '/tentang-kami/' ? 'bg-gray-100' : ''
                      } block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800`}
                    >
                      Tentang Kami
                    </Link>
                    <Link
                      to="/hubungi-kami/"
                      className={`${
                        path === '/hubungi-kami/' ? 'bg-gray-100' : ''
                      } block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800`}
                    >
                      Hubungi Kami
                    </Link>
                    <Link
                      to="/kebijakan-privasi/"
                      className={`${
                        path === '/kebijakan-privasi/' ? 'bg-gray-100' : ''
                      } block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800`}
                    >
                      Kebijakan Privasi
                    </Link>
                    <Link
                      to="/tips-judi-online/"
                      className={`${
                        path === '/tips-judi-online/' ? 'bg-gray-100' : ''
                      } block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800`}
                    >
                      Tips Judi Online
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Transition>
      </div>
    </header>
  )
}

export default Header
