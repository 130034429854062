import React, { useState } from 'react'
import { useFlexSearch } from 'react-use-flexsearch'
import { graphql, useStaticQuery } from 'gatsby'
import { Link } from 'gatsby'
import { Transition } from '@headlessui/react'
import OutsideClickHandler from 'react-outside-click-handler'

const SearchResult = ({ results }) =>
  results.length > 0 ? (
    results.map((result) => {
      return (
        <Link
          to={result.slug}
          key={result.id}
          className="flex items-start rounded-xl hover:bg-gray-50 transition ease-in-out duration-150 p-2"
        >
          <div className="hidden sm:block flex-shrink-0">
            <img
              title={result.title}
              alt={result.title}
              src={`data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=`}
              data-src={`https://ik.imagekit.io/gubelinlab/tr:w-128,h-80,f-auto/${result.featured_image}`}
              className="w-32 h-20 object-cover rounded-lg lazyload blur-up"
              loading="lazy"
            />
          </div>
          <div className="ml-4">
            <p className="text-base font-medium text-gray-900">{result.title}</p>
            <p className="mt-1 text-sm text-gray-500">{result.description}</p>
          </div>
        </Link>
      )
    })
  ) : (
    <div className="flex items-start rounded-3xl hover:bg-gray-50 transition ease-in-out duration-150">
      <p className="text-base font-medium text-gray-900">
        Oops, Pencarian Anda Tidak Ditemukan! Silahkan Coba Dengan Kata Kunci Lain!
      </p>
    </div>
  )

const SearchBar = () => {
  const data = useStaticQuery(graphql`
    query SearchBarQuery {
      localSearchPosts {
        index
        store
      }
    }
  `)

  const {
    localSearchPosts: { index: dataIndex, store: dataStore },
  } = data
  const index = dataIndex

  const store = dataStore
  const [query, setQuery] = useState('')
  const [isOpen, setIsOpen] = useState(false)
  const results = useFlexSearch(query, index, store)

  function setToZero() {
    setIsOpen(false)
    setQuery('')
  }

  return (
    <OutsideClickHandler onOutsideClick={() => setToZero()}>
      <label htmlFor="search" className="sr-only">
        Search
      </label>
      <div className="relative">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <svg
            className="h-5 w-5 text-gray-400"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <input
          type="text"
          name="search"
          id="search"
          className="block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-gray-800 text-gray-300 placeholder-gray-400 focus:outline-none focus:bg-white focus:border-rose-500 focus:ring-rose focus:text-gray-900 sm:text-sm"
          placeholder="Search Tips Judi Online"
          value={query}
          onChange={(e) => {
            setQuery(e.target.value)
            setIsOpen(true)
          }}
        />
        <Transition
          show={isOpen}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          {(ref) => (
            <div
              ref={ref}
              className={`absolute z-10 left-1/2 transform -translate-x-1/2 mt-3 px-2 w-screen max-w-md md:max-w-xl sm:px-0 ${
                !isOpen ? 'hidden' : ''
              }`}
            >
              <div className="rounded-3xl shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                  {query ? <SearchResult results={results} /> : ''}
                </div>
              </div>
            </div>
          )}
        </Transition>
      </div>
    </OutsideClickHandler>
  )
}

export default SearchBar
