import React from 'react'
import PropTypes from 'prop-types'

const Icon = ({ htmlClass }) => {
  return (
    <svg className={htmlClass} viewBox="0 0 500 500">
      <path
        d="M250,0C112.5,0,0,112.5,0,250S112.5,500,250,500,500,387.5,500,250,387.5,0,250,0ZM110.71,389.29c-37.5-37.5-57.14-87.5-57.14-135.72L248.21,446.43C198.21,444.64,148.21,426.79,110.71,389.29Zm182.15,51.78L58.93,207.14C78.57,119.64,157.14,53.57,250,53.57c66.07,0,123.21,32.14,158.93,80.36l-26.79,23.21C351.79,116.07,303.57,89.29,250,89.29c-69.64,0-128.57,44.64-151.79,107.14L303.57,401.79c51.79-17.86,91.07-62.5,103.57-116.08H321.43V250h125C446.43,342.86,380.36,421.43,292.86,441.07Z"
        fill="#e9415f"
      />
    </svg>
  )
}

Icon.propTypes = {
  htmlClass: PropTypes.string,
}

export default Icon
